import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import BlockSixUp from '../components/BlockSixUp';
import BlockTestimonialsSlider from '../components/BlockTestimonialsSlider';
import Meta from '../components/Meta';
import BlockCTA from '../components/BlockCTA';
import BlockOverview from '../components/BlockOverview';
import bgHero from '../assets/images/hero--retail.png';
import bgContact from '../assets/images/bg--contact--retail.jpg';
import BlockRetailHeroForm from '../components/BlockRetailHeroForm';
import BlockRetailSixUp from '../components/BlockRetailSixUp';
import BlockPhotoLeft from '../components/BlockPhotoLeft';
import BlockRetailOverview from '../components/BlockRetailOverview';
import BlockRetailTestimonial from '../components/BlockRetailTestimonial';
import BlockRetailContact from '../components/BlockRetailContact';
import NavRetail from '../components/NavRetail';
import FooterRetail from '../components/FooterRetail';
import MetaRetail from "../components/MetaRetail";

export default function Index() {
  useEffect(() => {
    const contactLinks = document.querySelectorAll(`a[href='#contact']`);
    const contact = document.querySelector(`#contact`);

    contactLinks.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        contact.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });

    const body = document.querySelector('body');
    body.classList.add('body--primary');
  }, []);

  return (
    <>
      <MetaRetail
        title="CloudRetail"
        description="Our commercial kitchens are designed to help you minimize cost and maximize your delivery revenue. Find one in your neighborhood."
        url="https://retail.cloudkitchens.com/"
      />
      <NavRetail />
      <BlockRetailHeroForm
        button={['speak to an expert']}
        color="grey-1"
        page="Retail"
        title="An easier way to sell and deliver your products"
        copy="With your own virtual storefront, you can reach millions of customers ordering online. Have your products delivered direct-to-consumer in 30 minutes. We’re here to get you going. And growing."
        video
        hero={bgHero}
        info={false}
        parentId="#contactTop"
      />
      <BlockRetailSixUp
        title={["There's even more in store"]}
        eyebrow
        content={[
          'With CloudRetail, you get all the perks of opening your own virtual storefront, plus all the expertise and infrastructure of CloudKitchens',
          <sup>&reg;</sup>,
          ' to keep your items flying off the shelf',
        ]}
        button="Get started"
        title_1={[
          'Store your',
          <br className="hidden lg:block" />,
          ' consumer goods',
        ]}
        content_1="Have access to our state-of-the-art storage facilities for all your products, while keeping your own workspace free and open."
        title_2={[
          'Let us handle the',
          <br className="hidden lg:block" />,
          ' logistics',
        ]}
        content_2="We’ll take care of inbound receiving and logistics, so you can focus on expanding your business."
        title_3={[
          'Get the support',
          <br className="hidden lg:block" />,
          ' you need',
        ]}
        content_3="Have a top-notch team of experts to partner with on inventory management, real-time reporting, affiliate program access, and more."
        title_4={[
          'Test your concept ',
          <br className="hidden lg:block" />,
          ' with low risk',
        ]}
        content_4="Experiment with innovative items or new flavors with little risk before pitching them to retailers. And once in the marketplace, get instant data on how they’re doing."
        title_5={[
          'Reach new',
          <br className="hidden lg:block" />,
          ' customers online',
        ]}
        content_5="Be seen by tens of millions of customers who are ordering food online, and get your items added to their delivery orders."
        title_6={[
          'Unlock new',
          <br className="hidden lg:block" />,
          ' revenue streams',
        ]}
        content_6="Easily open up your direct-to-consumer channel in just two weeks, then watch your sales volume grow."
      />
      <BlockRetailOverview
        color="primary"
        eyebrow="How it works"
        title={[
          "We'll make it",
          <br className="hidden lg:block" />,
          ' work for you',
        ]}
        content="Opening and operating your virtual store is easier than you think. We take care of the small details, so you can focus on the big picture."
        button1="Talk to an expert"
        button2="Get started"
        title1={['Store your products in our space']}
        content1="We’re located in online food delivery hotspots, so your frozen, cold, and shelf-stable items will always be close at hand. We work with you to license the exact number of shelves you need to store your inventory."
        title2={['Get help building your digital storefront']}
        content2="Our team of experts will set you up with a unique storefront through popular food delivery platforms. You create the product, we’ll help you get it online. "
        title3={['We’ll handle the order fulfillment']}
        content3="We’ll pick and pack every order with care, get it in the hands of the right delivery driver, and take care of inventory management."
        title4={['Your products get delivered in 30 minutes']}
        content4="Have peace of mind knowing your items are delivered safely and on time."
      />
      <BlockRetailTestimonial
        title={["There's even more in store"]}
        eyebrow
        content="Thanks to CloudRetail we can now more quickly deliver our ice cream to more of our customers in more areas, which continues to be a great growth driver for us."
      />
      <BlockRetailContact
        button={['speak to an expert']}
        color="grey-1"
        page="Retail"
        hero={bgContact}
        info={false}
        parentId="#contact"
      />
    </>
  );
}
